@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald:wght@700&family=Tangerine:wght@700&display=swap');

html {
  overflow-y:scroll;
}

.body{
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  
}

.home-div{
  background-color: #151B26;
  display:flex;
  min-height: 100vh;
  justify-content: center;
  
}

.main-container{
  width: 90%;

}
.main-title{
font-family: 'Tangerine';
font-style: normal;
font-weight: bold;
font-size: 3rem;
text-align: center;
letter-spacing: 0.1em;
color: #1F638C;
}

.main-title-div{

    background-color: #F6F1F1;
    height: 4rem;
    display: grid;
    grid-template-columns: 15% 70% 15%;
    grid-auto-rows: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    margin-bottom: 1rem;

}

.blue-line{
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 40px;
  height: 5px;
  background-color: #03658C;
}

.top-container{
  display: grid;
  /* grid-template-columns: 14% 14% 14% 14% 14% 14% 14% 14% 14% ; */
  grid-template-columns: repeat(9, auto);
}

.bottom-container{
  display: grid;
  grid-template-columns: 40% 20% 40%;

}

.middle-container {
  display: grid;
  grid-template-rows: 20% auto 60%;
}

.pop-up-container{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.52);
  display: flex;
  justify-content: center;
  align-items: center;
}

.stairs-container{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.stair-step{
  background-color: #03658C ;
}

.blue-line-bottom{
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
  border-radius: 40px;
  height: 5px;
  background-color: #03658C;
}

.vertical-blue-line{
  background-color: #03658C;
  width: 0.3rem;
}

.main-container-grid{
  display: grid;
  grid-template-columns: 25% 1% 48% 1% 25%;

}

.left-container{
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
}

.right-container{
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
}

.first-row-middle-container{
  display: grid;
  grid-template-columns: repeat(6, auto);
}

.second-row-middle-container{
  display: flex;
  justify-content: space-between;
  position: relative;
  height: inherit;
}

.windows-div {
  display: flex;
  position: relative;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.windows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #1F638C;
  position: relative;
  border-radius: 45% 45% 0px 0px; 
  width: 65%;
  height: 85%;
}

.windows-top-panes {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  width: 100%;
  height: 50%;
}

.windows-top-panes-rectangles-left {
  background: #BF3C1F;
  position: absolute;
  border-radius: 80% 0px 0px 0px;
  bottom: 11%;
  left: 19%;
  width: 24%;
  height: 63%; 
}

.windows-top-panes-rectangles-right {
  background: #BF3C1F;
  position: absolute;
  border-radius: 0px 80% 0px 0px;
  bottom: 11%;
  right: 19%;
  width: 24%;
  height: 63%; 
}

.windows-bottom-panes {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 50%;
  position: relative;
}

.windows-bottom-panes-rectangles-left {
  background: #BF3C1F;
  position: absolute;
  bottom: 19%;
  left: 19%;
  width: 24%;
  height: 75%; 
}

.windows-bottom-panes-rectangles-right {
  background: #BF3C1F;
  position: absolute;
  bottom: 19%;
  right: 19%;
  width: 24%;
  height: 75%; 
}

.second-sub-row-middle-container{
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.third-row-middle-container{
  display: grid;
  grid-template-columns: 36% 28% 36%;

}

.first-sub-row-middle-container{
  display: grid;
  align-items: center;
}

.third-sub-row-middle-container{
  display: grid;
  align-items: center;
}

.first-sub-row-flex{
  display: flex;
  justify-content: space-between;

}

.second-sub-row-flex{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.line {
  width: 100%;
  height: 1px;
  position: absolute;
  border-bottom: 1px solid black;
}

.rectangle-for-diamond {
  display: flex;
  align-items: center;
  position: relative;
  width: 97%;
  height: 100%;
  box-sizing: border-box;
  background: #BF3C1F;
}

.diamonds-div {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: space-evenly;
  align-items: center;
}

.diamonds {
  width: 5%;
  height: 60%;
  background: #1F638C;
  transform: rotate(-45deg);
}

.main-container-phone{
  display: flex;
  justify-content: center;
  background-color: #151B26;
}

.main-sub-container-phone{
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-items: center;

}

.first-container-grid-phone{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 3px;
  justify-items: center;
  align-items: center;
}

.pictures-with-windows-phone {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rectangle-for-diamond-phone {
  display: flex;
  align-items: center;
  position: relative;
  width: 97%;
  height: 2.25rem;
  box-sizing: border-box;
  background: #BF3C1F;
  margin: 1rem 0rem;
}

.home-icon{
  font-size: 45px;
  color: #BF3C1F;
  text-align: center;
}

.home-icon-white{
  font-size: 69px;
  color: white;
  text-align: center;

}


.before-icon{
  font-size: 60px;
  color: #BF3C1F;
  text-align: center;
  
}

.link-align{
  text-align: center;
}
.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 500, 'GRAD' 0, 'opsz' 48;
}

.test{
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.div-test{
  width: 18%;
}
.arepa{
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}



audio::-webkit-media-controls-play-button {
  background-color: #bab8b777;
  border-radius: 50%;
}

audio::-webkit-media-controls-play-button:hover {
  background-color: #9d9b9b77;
}


@media only screen and (max-width: 700px) {


.main-title-div{
 margin-top: 2rem;
 grid-template-columns: 90% 10%;
}

.main-title{
  font-size: 2rem;

}

.second-main-menu-container{
  display: flex !important;
  flex-direction: column;
}

.main-menu-button{
  width: 15rem !important;
}

.home-page-pics{
  grid-template-columns: 100% !important;
  grid-template-rows: 50% 50%;
  margin-bottom: 3rem;
}



}
