.main-menu-page-container{
    height: 100vh;
    background: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-menu-sub-container{
    width: 50%;
    display: grid;
    grid-template-rows: 50% 50%;
    justify-items: center;
}

.main-menu-button{
    background-color: #BF3C1F;
    color: white;
    padding: 1.2rem;
    border-radius: 12px;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    transition:0.4s;
}


.main-menu-button:hover {
    transform: scale(1.1); 
  }


.second-main-menu-container{
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
    align-items: center;
}

.italics{
    font-style: italic;
}
.home-page-div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background:#fff;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100%;
    height: max-content;
}

.home-page-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin-top: 2rem;
  
}

.main-page-title {
  color: #405950;
  font-size: 2.5rem;
  font-family: Oswald;
  text-align: center;
}

.main-page-text {
  font-family: Open Sans,Arial,sans-serif;
  color: #666;;
  /* background-color: lightcoral; */
  text-align: center;
  max-height: 25%;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .main-page-text::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* .main-page-text {
-ms-overflow-style: none;  
scrollbar-width: none;  
} */

.read-more-link {
  color: white;
}

.read-more-text {
  display: none;
}

.read-more-text--show {
  display: inline;
}

.read-more-btn {
  color: white;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  font-size: 1rem;

}

.main-page-button {
  background-color: #BF3C1F;
  color: white;
  padding: 1rem;
  border-radius: 12px;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 12rem;

}

.home-page-pics {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-items: center;
  grid-gap: 1.8%;
  margin-top: 2rem;
}

.button-div{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hyperlink{
    color: #BF3C1F;
}
.img-botton-div{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-page-text{
  text-align: justify;
}
@media only screen and (max-width: 700px) {

.home-page-container{
    width: 80%;
  }

.home-page-pics{
 
  display: block;

}
}