.card{
    background-color: #BF3C1F;
}

.card h2{
    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0.8rem 0rem;
    text-align: center;
    padding: 0.5rem;
    color: #204465;
}