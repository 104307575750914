@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Caveat&family=Cormorant+Upright&family=Fuzzy+Bubbles&family=Indie+Flower&family=Kranky&family=Lobster+Two&family=Patrick+Hand&family=Shadows+Into+Light+Two&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald:wght@700&family=Tangerine:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,700;1,400;1,700&family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rectangle-container{
    display: flex;
    justify-content: center;
    align-items: center;

}

.rectangle-picture{
    transition:0.4s;
    /* height: max-content; */
}

.rectangle-picture:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); 
  }


  @media only screen and (max-width: 700px) {
    .rectangle-picture{
        height: auto;
    }

  } 
.card{
    background-color: #BF3C1F;
}

.card h2{
    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    margin: 0.8rem 0rem;
    text-align: center;
    padding: 0.5rem;
    color: #204465;
}
.pop-up-div{
    background-color: white;
    height: 75%;
    width: 70%;
    display: grid;
    grid-template-columns: 50% 50%;
   
}

.pop-up-div-only-audio{
    background-color: white;
    height: 75%;
    width: 70%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.pop-up-container-left{
    padding: 1.5rem;
}

.pop-up-container-right{
    padding: 1rem;
    padding-right: 3.5rem;
    overflow-y: scroll;
}
.pop-up-flex-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;

}

.pop-up-flex-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30vh;
    
}

.pop-up-grid{
    display: grid;
    grid-template-rows: 70% 15% 15%;
    justify-items: center;
}

.pop-up-statement-text{
    font-family: 'Open Sans';
    font-size: 0.95rem;
}

.pop-up-by-embroiderer-text{
    font-family: 'Open Sans';
    font-weight: bold;
    margin-top: 2rem;

}

.emborider-info-text{
    width:87%;
}

.main-text {
    font-size: 1rem;
}

.redwing-title{
    font-size: 1.3rem;
}

.redwing-title-image-statement{
    font-size: 1.1rem;
    margin-top: 10%;
}
 .redwing-title h2{
    margin-top: 10%;
 }

 .redwing-translation-image-statement {
    color:maroon;
    font-weight: 700;
    font-size: 1.2rem;
}

 
.redwing-translation {
    color:maroon;
    font-weight: 700;
    font-size: 1.2rem;
}

.translation {
    color:maroon;
    font-weight: 700;
    font-size: 1.1rem;
}

.embroiderer-name{
    margin-top: 0rem;
    font-weight: bold;
}

.first-pop-up-div{
    display: grid;
    grid-template-columns: 50% 50%;
}

.second-pop-up-div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1%;

}

.only-audio-pop-up-container-left {
    padding: 0rem;
    margin-top: 10%;
}

.only-audio-pop-up-container-right {
    padding-top: 0rem;
    padding-right: 4.5rem;
    padding-left: 0.2rem;
}

.only-audio-pop-up-flex-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40vh;
}

.personalized-only-audio-pop-up-flex-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 40vh;
}


.title-image-statement {
    font-size: 1.1rem;
}

.translation-image-statement{
    font-size: 1.1rem;
}
.redwing-pop-up-flex-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.rw-personalized-first-pop-up-div{
    display: grid;
    grid-template-columns: 50% 50%;
    /* height: 100%; */
}

.rw-personalized{
    justify-content: center;
}

.personalized-title{
    margin-top: 0%;
}
@media only screen and (max-width: 700px) {
    .pop-up-div{
        height: 83%;
        width: 70%;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }
    .pop-up-container-right{
        overflow-y: visible;
        overflow-y: initial;
    }
    .pop-up-container-right{
        padding: 0 2rem 2rem 2rem;
    }

    .pop-up-container-left{
        padding: 1rem 1rem 0 1rem;
    }
    .pop-up-flex-column{
        height: 100%;
    }

    .pop-up-img{
        width: 100% !important;
        height: auto !important;
    }

  } 

html {
  overflow-y:scroll;
}

.body{
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  
}

.home-div{
  background-color: #151B26;
  display:flex;
  min-height: 100vh;
  justify-content: center;
  
}

.main-container{
  width: 90%;

}
.main-title{
font-family: 'Tangerine';
font-style: normal;
font-weight: bold;
font-size: 3rem;
text-align: center;
letter-spacing: 0.1em;
color: #1F638C;
}

.main-title-div{

    background-color: #F6F1F1;
    height: 4rem;
    display: grid;
    grid-template-columns: 15% 70% 15%;
    grid-auto-rows: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    margin-bottom: 1rem;

}

.blue-line{
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 40px;
  height: 5px;
  background-color: #03658C;
}

.top-container{
  display: grid;
  /* grid-template-columns: 14% 14% 14% 14% 14% 14% 14% 14% 14% ; */
  grid-template-columns: repeat(9, auto);
}

.bottom-container{
  display: grid;
  grid-template-columns: 40% 20% 40%;

}

.middle-container {
  display: grid;
  grid-template-rows: 20% auto 60%;
}

.pop-up-container{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.52);
  display: flex;
  justify-content: center;
  align-items: center;
}

.stairs-container{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.stair-step{
  background-color: #03658C ;
}

.blue-line-bottom{
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
  border-radius: 40px;
  height: 5px;
  background-color: #03658C;
}

.vertical-blue-line{
  background-color: #03658C;
  width: 0.3rem;
}

.main-container-grid{
  display: grid;
  grid-template-columns: 25% 1% 48% 1% 25%;

}

.left-container{
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
}

.right-container{
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
}

.first-row-middle-container{
  display: grid;
  grid-template-columns: repeat(6, auto);
}

.second-row-middle-container{
  display: flex;
  justify-content: space-between;
  position: relative;
  height: inherit;
}

.windows-div {
  display: flex;
  position: relative;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.windows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #1F638C;
  position: relative;
  border-radius: 45% 45% 0px 0px; 
  width: 65%;
  height: 85%;
}

.windows-top-panes {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  width: 100%;
  height: 50%;
}

.windows-top-panes-rectangles-left {
  background: #BF3C1F;
  position: absolute;
  border-radius: 80% 0px 0px 0px;
  bottom: 11%;
  left: 19%;
  width: 24%;
  height: 63%; 
}

.windows-top-panes-rectangles-right {
  background: #BF3C1F;
  position: absolute;
  border-radius: 0px 80% 0px 0px;
  bottom: 11%;
  right: 19%;
  width: 24%;
  height: 63%; 
}

.windows-bottom-panes {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 50%;
  position: relative;
}

.windows-bottom-panes-rectangles-left {
  background: #BF3C1F;
  position: absolute;
  bottom: 19%;
  left: 19%;
  width: 24%;
  height: 75%; 
}

.windows-bottom-panes-rectangles-right {
  background: #BF3C1F;
  position: absolute;
  bottom: 19%;
  right: 19%;
  width: 24%;
  height: 75%; 
}

.second-sub-row-middle-container{
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.third-row-middle-container{
  display: grid;
  grid-template-columns: 36% 28% 36%;

}

.first-sub-row-middle-container{
  display: grid;
  align-items: center;
}

.third-sub-row-middle-container{
  display: grid;
  align-items: center;
}

.first-sub-row-flex{
  display: flex;
  justify-content: space-between;

}

.second-sub-row-flex{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.line {
  width: 100%;
  height: 1px;
  position: absolute;
  border-bottom: 1px solid black;
}

.rectangle-for-diamond {
  display: flex;
  align-items: center;
  position: relative;
  width: 97%;
  height: 100%;
  box-sizing: border-box;
  background: #BF3C1F;
}

.diamonds-div {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: space-evenly;
  align-items: center;
}

.diamonds {
  width: 5%;
  height: 60%;
  background: #1F638C;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.main-container-phone{
  display: flex;
  justify-content: center;
  background-color: #151B26;
}

.main-sub-container-phone{
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-items: center;

}

.first-container-grid-phone{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 3px;
  justify-items: center;
  align-items: center;
}

.pictures-with-windows-phone {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rectangle-for-diamond-phone {
  display: flex;
  align-items: center;
  position: relative;
  width: 97%;
  height: 2.25rem;
  box-sizing: border-box;
  background: #BF3C1F;
  margin: 1rem 0rem;
}

.home-icon{
  font-size: 45px;
  color: #BF3C1F;
  text-align: center;
}

.home-icon-white{
  font-size: 69px;
  color: white;
  text-align: center;

}


.before-icon{
  font-size: 60px;
  color: #BF3C1F;
  text-align: center;
  
}

.link-align{
  text-align: center;
}
.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 500, 'GRAD' 0, 'opsz' 48;
}

.test{
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.div-test{
  width: 18%;
}
.arepa{
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
}



audio::-webkit-media-controls-play-button {
  background-color: #bab8b777;
  border-radius: 50%;
}

audio::-webkit-media-controls-play-button:hover {
  background-color: #9d9b9b77;
}


@media only screen and (max-width: 700px) {


.main-title-div{
 margin-top: 2rem;
 grid-template-columns: 90% 10%;
}

.main-title{
  font-size: 2rem;

}

.second-main-menu-container{
  display: flex !important;
  flex-direction: column;
}

.main-menu-button{
  width: 15rem !important;
}

.home-page-pics{
  grid-template-columns: 100% !important;
  grid-template-rows: 50% 50%;
  margin-bottom: 3rem;
}



}

.lanesboro-container{
    width: 100%;
    height: 100vh;
}
.lanesboro-main-container{
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 30% 70%;
    /* background-color: black; */
    
    /* background-image: url(https://main-page-images-quilt.s3.amazonaws.com/background-lanes.png);
    background-repeat: round;
    background-size: 25rem auto; */
}

.lanesboro-vertical-container{
   
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-container-sub{
    height: 95%;
    width: 90%;
    display: grid;
    grid-template-rows: 10% 30% 30% 30%;
}

.lanesboro-horizontal-container{
    display: grid;
    grid-template-rows: 37% 26% 37%;
}

.lanesboro-horizontal-container .first-div{
    display: flex;
}

.lanesboro-horizontal-container .third-div{
    display: flex;
}

.rectangle-6{
    border-radius: 50%;
    width: 11rem !important;
    height: 11rem ;
}

.rectangle-2{
    margin-top: 15%;
}

.rectangle-3{
    margin-bottom: 10%;
}

.rectangle-7{
    margin-left: 40%;
}

.rectangle-6{
    margin-right: 30%;
}

.rectangle-3{
    border-radius: 40% 0 0 40%;
}

.rectangle-9{
    margin-bottom: 20%;
}

.rectangle-11{
    margin-bottom: 10%;
}

.rectangle-4{
    border-radius: 25% 25% 25% 0;
}

.lanesboro-rectangle{
    border: black 3px solid;
}

.title-div{
    background: rgb(59,196,172);
    background: linear-gradient(0deg, rgba(59,196,172,1) 0%, rgba(191,217,210,1) 100%);
    width: 90%;
    border-radius: 25px;
    border: black solid 2px;
    margin: 0;
    padding: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.title-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.title-text{
    font-family: 'Merriweather', serif;
    font-style: italic;
    color: #054370;
    letter-spacing: 3px;
    font-size: 250%;
    margin: 0;
    text-align: center;
}

.lanesboro-second-panel-main-container{
    display: grid;
    grid-template-columns: 10% 20% 31% 31% 8% ;

}

.second-panel-first-div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
}

.second-panel-first-div .first-div-sub{
    height: 70%;
    display: grid;
    grid-template-rows: 55% 45%;
    justify-items: center;

}

.second-panel-second-div{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.rectangle-13{
    margin-right: 2.5rem;
}

.rectangle-23{
    margin-right: 2.5rem;
}

.second-panel-third-div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.third-div-sub{
    height: 70%;
    display: grid;
    grid-template-rows: 40% 30% 30%;

}
.rectangle-19-sub-div{
    display: grid;
    grid-template-columns: 30% 70%;

}

.rectangle-14{
    margin-right: 5rem;
}

.rectangle-24{
    margin-right: 5rem;
}

.rectangle-18-sub-div{
    display: grid;
    grid-template-columns: 25% 75%;
}

.arrows{
    color: #E7683B;
    font-size: 7rem;
    transition:0.4s;
}



.arrow-div{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.arrows:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 
}

.arrow-back{
    margin-left: 2.4rem;
}

.arrow-forward{
    margin-right: 2rem;
}

.lanesboro-background{
    height: 100vh;
    z-index: -1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.top-image{
    background-image: url('https://main-page-images-quilt.s3.amazonaws.com/top-image.jpg');
    height: 33vh;
    background-repeat: round;
    background-size: 30rem auto;
}


.middle-image{
    background-image: url('https://main-page-images-quilt.s3.amazonaws.com/middle-image.jpg');
    height: 34vh;
    background-repeat: round;
    background-size: 30rem auto;
}

.rectangle-14{
    border-radius: 0 0 30px 30px ;
}

.lanesboro-third-panel-main-container{
    display: grid;
    grid-template-columns: 10% 15% 48% 27%;
    height: inherit;
}

.third-panel-second-div{
    display: grid;
    grid-template-rows: 33% 33% 33%;
}

.third-panel-first-div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.third-panel-second-div-first-container{
    display: grid;
    grid-template-columns: 60% 40%;
}

.third-panel-second-div-second-container{
    display: grid;
    grid-template-columns: 40% 60%;
}

.third-panel-third-div{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.rectangle-17{
    border-radius: 0 0 50px 50px;
}

.third-panel-title{
    background: rgb(59,196,172);
    background: linear-gradient(0deg, rgba(59,196,172,1) 0%, rgba(191,217,210,1) 100%);
    height: 4rem;
    width: 55%;
    display: flex;
    justify-content: center;
    border: solid 2px black;

}


.top{
    margin-left: 5rem;
}
.bottom{
    margin-top: 2rem;
   margin-left: 25%;
    
}

.third-panel-second-div-third-container{
    display: flex;
    flex-direction: column;
}
.third-panel-title h3{
    font-family: 'Merriweather', serif;
    font-style: italic;
    color: #054370;

}

.rectangle-21{
    border-radius: 50%;
}

.rectangle-18{
    border: none;
}

.rectangle-phone-18{
    border: none;
}

.rectangle-phone-22{
    border: none;
}
.rectangle-22{
    border: none;
}

.rectangle-25{
    border: none;
}

.rectangle-phone-25{
    border: none;
}


.rectangle-20{
    border: none;
}

.rectangle-phone-20{
    border: none;
}


.phone-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.phone-sub-container{
    margin-top: 3rem;
    margin-bottom: 5rem;
    width: 95%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.phone-title{
 font-size: 1rem;
 text-align: center;

}

.arrow-div-left{
    display: grid;
    grid-template-rows: 15% 70% 15%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.link-container{
    display: grid;
    justify-content: left;
    align-items: center;
    text-align: center;
    margin-left: 5%;
    margin-bottom: 15%;

}

@media only screen and (max-width: 700px) {
.title-div{
    width: 97%;
    margin-top: 2rem;
    border: none;
    border-radius: 0px;
}
   
}
.main-menu-page-container{
    height: 100vh;
    background: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-menu-sub-container{
    width: 50%;
    display: grid;
    grid-template-rows: 50% 50%;
    justify-items: center;
}

.main-menu-button{
    background-color: #BF3C1F;
    color: white;
    padding: 1.2rem;
    border-radius: 12px;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    transition:0.4s;
}


.main-menu-button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 
  }


.second-main-menu-container{
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
    align-items: center;
}

.italics{
    font-style: italic;
}
.home-page-div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background:#fff;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100%;
    height: -webkit-max-content;
    height: max-content;
}

.home-page-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin-top: 2rem;
  
}

.main-page-title {
  color: #405950;
  font-size: 2.5rem;
  font-family: Oswald;
  text-align: center;
}

.main-page-text {
  font-family: Open Sans,Arial,sans-serif;
  color: #666;;
  /* background-color: lightcoral; */
  text-align: center;
  max-height: 25%;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .main-page-text::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* .main-page-text {
-ms-overflow-style: none;  
scrollbar-width: none;  
} */

.read-more-link {
  color: white;
}

.read-more-text {
  display: none;
}

.read-more-text--show {
  display: inline;
}

.read-more-btn {
  color: white;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  font-size: 1rem;

}

.main-page-button {
  background-color: #BF3C1F;
  color: white;
  padding: 1rem;
  border-radius: 12px;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 12rem;

}

.home-page-pics {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-items: center;
  grid-gap: 1.8%;
  margin-top: 2rem;
}

.button-div{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hyperlink{
    color: #BF3C1F;
}
.img-botton-div{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-page-text{
  text-align: justify;
}
@media only screen and (max-width: 700px) {

.home-page-container{
    width: 80%;
  }

.home-page-pics{
 
  display: block;

}
}
.rw-main-div{
    display:  grid;
    grid-template-columns: 20% 60% 20%;

}

.redwing-first-panel-welcome-sign{
    background-image: url(https://main-page-images-quilt.s3.amazonaws.com/redwing-welcome-sign.png);
    background-repeat: repeat;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:6%;
    width: 60%;
    height: 40%;
}

.i-know-img{
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

}
.flex{
    display: flex;
    margin-left: 7%;
    margin-right: 7%;
}
.rw-first-panel-first-div{
    display: flex;
    margin-left: 1rem;
    padding-top: 3%;
}

.rw-first-panel-second-div{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin-left: 3%; 
    margin-right: 7%;
    margin-top: -62%;
}
.rw-home-icon{
    color:white;
    text-align: center;
    font-size: 500%;
    margin-left: 2.2rem;
    margin-top: 2rem;
    transition:0.4s;
}


.rw-home-icon-div{
    background-color: #003157;
    display: flex;
}

.rw-home-icon-div-first-panel{
    background-color: #003157;
}

.rw-next-arrow-div{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #003157;
    align-items: center;
}

.rw-arrow-back-div{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #003157;
    align-items: center;
}


.rw-background{
    background-image: url(https://main-page-images-quilt.s3.amazonaws.com/rw-background-17.jpg);
   
    /* background-image: url(https://main-page-images-quilt.s3.amazonaws.com/rw-background-2.jpg); */
    /* background-image: url('https://main-page-images-quilt.s3.amazonaws.com/rw-backgorund-3.jpg'); */
    /* background-image: url('https://main-page-images-quilt.s3.amazonaws.com/rw-background-4.jpg'); */
    /* background-image: url('https://main-page-images-quilt.s3.amazonaws.com/rw-background--2.jpg'); */
    /* background-image: url(https://main-page-images-quilt.s3.amazonaws.com/background-5.jpg); */
    /* background-image: url(https://main-page-images-quilt.s3.amazonaws.com/rw-background-10.jpg); */
    /* background-image: url(https://main-page-images-quilt.s3.amazonaws.com/rw-background-12.jpg); */
    /* background-image: url(https://main-page-images-quilt.s3.amazonaws.com/background-7.jpg); */
    /* background-image: url(https://main-page-images-quilt.s3.amazonaws.com/rw-background-14.jpg); */
    /* background-image: url(https://main-page-images-quilt.s3.amazonaws.com/rw-backgorund-13.JPG); */
    /* background-image: url(https://main-page-images-quilt.s3.amazonaws.com/rw-background-15.jpg); */
    /* background-image: url(https://main-page-images-quilt.s3.amazonaws.com/rw-background-20.jpg); */
    /* background-image: url(https://main-page-images-quilt.s3.amazonaws.com/rw-background-18.jpg); */
    /* background-image: url(https://main-page-images-quilt.s3.amazonaws.com/rw-background-21.jpg); */
    background-repeat: round;
    background-size: 9rem auto;
    padding: 1rem;
}

.rw-quilt-container{
    height: 100vh;
    overflow-y: scroll;
    
}

.rw-quilt-sub-container{
    z-index: -1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

}

.first-panel-first-div{
    display: grid;
    grid-template-columns: 55% 45%;
    height: 20%;

}

.redwing-first-panel-main-div{
    display: grid;
    grid-template-columns: 18% 71% 11%;
    height: 100vh;
    width: 100%;
}

.rw-arrows{
    color: white;
    font-size: 600%;
    transition:0.4s;
}

.rw-arrows:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 
}

.rw-home-icon:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 
}

.first-panel-first-sub-div{
   
    display: flex;
    justify-content: center;
}

.red-wing-first-panel-second-column{
    height: 50vh;
}

.red-wing-first-panel-second-main-div{
    height: 80%;
    display: flex;
}

.rw-second-panel-first-div{
    display: grid;
    grid-template-columns: 30% 30% 40%;
    margin-left: 3%;
    margin-right: 4%;
    margin-top: -147%;
}
.rw-second-panel-second-div{
    display: grid;
    grid-template-columns: 30% 40% 30%;
}
.rw-first-panel-third-div{
    display: flex;
}

.rw-second-panel-first-div-sub-div{
    display: flex;
}

.rw-rectangle-21{
    margin-right: 20%;
}

.rw-rectangle-38{
    margin-top: 25%;
    margin-right: 10%;
}

.rw-rectangle-32{
    margin-top: 80%;
    margin-right: 30%;
}

.rw-rectangle-15{
    margin-left: 20%;
}

.rw-rectangle-40{
    margin-left: 47%;
}

.rw-rectangle-54{
    margin-left: 110%;
    margin-top: 10%;
}

.rw-rectangle-44{
    margin-left: 73%;
    margin-top: 3%;
}

.rw-rectangle-45{
    margin-left: 15%;
}

.rw-rectangle-36{
    margin-left: 15%;
}

.rw-rectangle-53{
   margin-bottom:  50%;
   margin-right:  20%;
}

 .rw-rectangle-20{
    margin-left:  80%;
 }

 .rw-rectangle-49{
    margin-left:  80%;
 }

.rw-rectangle-55{
    margin-left:  30%;
}

.rw-rectangle-42{
    margin-top:  30%;
   
}

.rw-rectangle-51{
    margin-right:  16%;
}

.rw-rectangle-48{
    margin-right:  35%;
    margin-top: 7%;
}


.rw-rectangle-16{
    margin-left:  20%;
    
}

.rw-rectangle-2{
    margin-left:  53%;
    margin-top: 5%;
    
}

.rw-rectangle-22{
    margin-top:  13%;
    margin-left: 7%;
    
}

.rw-rectangle-24{
    margin-left:  89%;
    margin-top: -10%;
    
}

.rw-rectangle-43{
    margin-top:  8%;
    
}

.rw-rectangle-46{
    margin-top:  22%;
    margin-left: 60%;
    
}

.rw-rectangle-5{
    margin-left:  82%;
    margin-top: 10%;
    
}

.rw-rectangle-11{
    margin-top:  45%;
    margin-left: 70%;
    
}

.rw-rectangle-8{
    margin-top:  10%;
    margin-left: 2%;  
}

.rw-rectangle-18{
    margin-right: 45%;
    margin-top: -85%;
}

.rw-rectangle-3{
    margin-right:18%;
    margin-top: 10%;
   
}

.rw-rectangle-12{
    margin-left:16%;

}

.rw-rectangle-59{
    margin-top: 5%;
    margin-left: 30%;
}

.rw-rectangle-57{
    margin-top: 100%;
    margin-left: 25%;
   
}

.rw-rectangle-26{
    margin-top: 80%;
    margin-right:18%;
}

.rw-rectangle-35{

    margin-right: 9%;
   
}

.rw-rectangle-58{
    margin-top: -5%;
}

.rw-rectangle-34{
    margin-right: 15%;
}

.rw-rectangle-47{
    margin-right: 15%;
}

.rw-rectangle-56{
    margin-top: 4%;
    margin-left: 10%;
}

.rw-rectangle-30{
    margin-left: 14%;
}

.rw-rectangle-9{
    margin-left: 45%;
    margin-top: 22%;
}

.rw-rectangle-28{
    margin-top: 18%;
    margin-left: 80%;
}

.rw-rectangle-39{
    margin-left: 53%;
    margin-top: 19%;
}

.rw-rectangle-27{
    margin-left: 33%;
}

.rw-rectangle-52{
    margin-left: 30%;
}
.rw-rectangle-19{
    margin-left: 65%;
}

.rw-rectangle-4{
    margin-left: 110%;
    margin-top: -2%;
}

.rw-rectangle-7{
    margin-left: 60%;
}

.rw-rectangle-25{
    margin-top: 8%;
    margin-right: 3%;
    
}

.rw-rectangle-14{
    margin-right: 10%;
    margin-top: 15%;
}

.rw-rectangle-41{
    margin-left: 35%;
    margin-top: 25%;
}

.rw-third-panel-river{
    margin-top: 94%;
    margin-left: -3%;
    width: 105%;

}

.rw-rectangle-6{
    margin-top: -20%;
    margin-right: 17%;
}

.rw-rectangle-37{
    margin-left: 20%;
}


.rw-rectangle-31{
    margin-top: -50%;
}

.rw-rectangle-1{
    margin-top: 25%;
}

.rw-rectangle-13{
    margin-right: 43%;
}

.rw-rectangle-10{
    margin-top: 20%;
    margin-right: 30%;
}

.rw-rectangle-29{
    margin-top: 64%;
    margin-right: 29%;
}


 .rw-first-panel-second-div-sub{
    display: grid;
    grid-template-columns: 50% 50%;
 }


 .rw-third-panel-first-div-second-sub-div{
    display: flex;
 }

.rw-second-panel-river{
    width: 105.5%;
    margin-left: -3%;
    margin-top: 55%;
}

.rw-first-panel-river{
    width: 105.5%;
    margin-left: -3%;
    margin-top: -12%;

}

.extra-padding-top{
    padding-top: 3rem;
}

.rw-third-panel-first-div{
    display: grid;
    grid-template-columns: 33% 34% 33%;
    padding-top: 2rem;
    margin-top: -154%;
    width: 100%;
}

.rw-third-panel-first-div-sub-div{
    display: flex;
    align-items: flex-start;
}

.rw-third-panel-second-div{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.rw-wandering-house-sign{
    width: 100%;
    margin-top: 90%;
}

.rectangle-phone{
    margin: 0%;
    margin-top: 4%;
    
}

.i-know-img-phone{
    width: 100%;
    margin-top: 5%;
}

.rw-phone-sub-container{
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 95%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.rw-wandering-house-sign-phone{
    width: 90%;
}

.rw-wandering-house-sign-div-phone{
    display: flex;
    justify-content: center;
    align-items: center;
}

.rw-phone-container{
    background-image: url(https://main-page-images-quilt.s3.amazonaws.com/background-5.jpg);
    background-repeat: round;
    background-size: 6rem auto;
}
